import { useState, useEffect } from "react"
import { Container } from "react-bootstrap"

export default function AcademicCalendar () {
    const [calendarData, setCalendarData] = useState([])
    const [fetched, setFetched] = useState(false);
    let newData = []

    useEffect(() => {
        fetch("https://mpscnk.aestree.com/api/website?calendar")
        .then(res => res.json())
        .then(data => {
          setCalendarData(data.calendar.monthly ? data.calendar.monthly : [])
        })
        .then(() => {
            setFetched(true)
        })
        
    }, [])

    useEffect(() => {
        if (fetched) {
            calendarData.map(data => {
                newData.push({
                    id: data.id,
                    name: data.name,
                    date: [data.startdate, data.enddate],
                    color: data.color,
                    allDay: true,
                    type: "holiday",
                    description: data.name
                })
            })
            window.$('#calendar').evoCalendar({
                theme: "Royal Navy",
                format: "yyyy-mm-dd",
                calendarEvents: newData,
                firstDayOfWeek: 1
            })
           console.log(calendarData)
           console.log(newData)
           //$("#calendar").html("CALENDAR DATA")
        }
    }, [fetched])

    return (
        <Container>
            <h2 className="h3 py-2 pt-4 text-center mb-4">Academic Calendar</h2>
            <div id="calendar"></div>
        </Container>
    )
}