import { useState, useEffect } from "react";

export default function Popup () {
    const [popupEnabled, setPopupEnabled] = useState(false);

    const popup__modal = function () {
	  
		setTimeout(function () {
		  const popUp = document.querySelector(".popup");
          popUp.classList.remove("popup__hidden");
	  
		  document.addEventListener("click", function () {
			popUp.classList.add("popup__hidden");
		  });
	  
		  setTimeout(function () {
			popUp.classList.add("popup__hidden");
		  }, 30000);
		}, 2000);
	}

    useEffect(() => {
        fetch("https://mpscnk.aestree.com/api/website?popup")
        .then(res => res.json())
        .then(data => {
            if (data.popup && data.popup.status == 1) {
                setPopupEnabled(true);
            }
        })

        if (popupEnabled) {
            popup__modal();
        }
    }, [popupEnabled])
    
    return (
        popupEnabled ? <div className="popup popup__hidden">
        <img className="popup__img" src="https://mpscnk.aestree.com/api/website/popup_image" alt="Image" />
      </div> : null
    )
}