import { Container } from "react-bootstrap"

export default function Uniform () {
    return (
        <Container>
            <h2 className="h3 py-2 pt-4 text-center mb-4">School Uniform</h2>
            <div style={{display: "grid", gridTemplateColumns: "repeat(2, max-content)", gap: "20px", width: "fit-content"}} className="mx-auto">
                <span style={{fontWeight: "bold"}}>Nur, to KG-1</span>
                <div>
                <p>Girl's: Yellow T-Shirt & Electric Blue Trouser</p>
                <p>Boy's: Yellow T-Shirt & Electric Blue Trouser</p>
                </div>

                <span style={{fontWeight: "bold"}}>KG-II to IV</span>
                <div>
                <p>Girl's: Half Sleeve Check Shirt & Skirt</p>
                <p>Boy's: Half Sleeve Check Shirt & Full Pant</p>
                </div>

                <span style={{fontWeight: "bold"}}>V to X</span>
                <div>
                <p>Girl's: Half Sleeve Check Kurti with Sarak & Full</p>
                <p>Boy's: Full Shirt & Full Pant</p>
                </div>
            </div>

            <div style={{display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "20px", justifyItems: "center", marginTop: "20px"}}>
                <div className="d-flex justify-content-start" style={{gap: "20px"}}>
                <span style={{fontWeight: "bold"}}>Boy's</span>
                Maroon Full Sweater Steel Grey Full Pant
                </div>
                <div className="d-flex justify-content-start" style={{gap: "20px"}}>
                <span style={{fontWeight: "bold"}}>Girl's</span>
                Maroon Full Sweater Black Slacks
                </div>
            </div>
        </Container>
    )
}