import { Container, Card, CardBody } from "react-bootstrap"

export default function Guidelines () {
    return (
        <Container>
            <h2 className="h3 py-2 pt-4 text-center">Important Guidelines</h2>
            <Card>
                <CardBody>
                    <ol className="mx-2 px-3">
                        <li>Parents are requested to visit Principal every Monday & Wednesday from 11:30 a.m. to 1:10 p.m.</li>
                        <li>Parents are requested to see their children prepare their lessons and do their home work regularly.</li>
                        <li>Parents are requested to collect their wards immediately after the school hours.</li>
                        <li>Parents are requested to inform the school if there is change in the address or telephone numbers.</li>
                        <li>Your child is the most important person in our school. Together we work for the development of your child and our student.</li>
                    </ol>
                </CardBody>
            </Card>
        </Container>
    )
}