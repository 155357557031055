import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Image, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
export default function TopNav() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Row className="justify-content-between">
          <Col md={2}>
            <div className="d-flex align-items-center">
              <Image id="logo" src="/images/logo.png"></Image>
              <Navbar.Brand href="#home" className="fs-5">
                Modern Public School, Chinakuri
              </Navbar.Brand>
            </div>
          </Col>

          <Col md={2}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={NavLink} to="/" href="/" end>
                  Home
                </Nav.Link>
                <NavDropdown title="About Us" id="basic-nav-dropdown">
                  <NavDropdown.Item as={NavLink} to="/aboutschool">
                    About School
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/ourmembers">
                    Our Members
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Our School" id="basic-nav-dropdown">
                  <NavDropdown.Item as={NavLink} to="/academic_calendar">
                    Academic Calendar
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/guidelines">
                    Important Guidelines
                  </NavDropdown.Item>

                  <NavDropdown.Item as={NavLink} to="/rules">
                    Rules and Regulations
                  </NavDropdown.Item>

                  <NavDropdown.Item as={NavLink} to="/schoolhours">
                    School Hours
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={NavLink} to="/mandatorydisclosure" style={{whiteSpace: "nowrap"}}>
                  Mandatory Disclosure
                </Nav.Link>
                <Nav.Link as={NavLink} to="/events?loc=events">
                  Events
                </Nav.Link>
                <Nav.Link as={NavLink} to="/contact">
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}
